import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import Industry from "./industry.jpg"
import Teaching from "./teaching.jpg"
import Images from "./images.jpeg"
import Placement from './Placemenet.png';
import { faStar, faUser } from '@fortawesome/free-regular-svg-icons';
import { faChalkboardUser, faFlask } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import gdlogo from './images/groupdiscussion.png';
import pdlogo from './images/personalitydevelopment.png';
import ewlogo from './images/emailwriting.png';
import islogo from './images/interviewsession.png';
import mtlogo from './images/mocktest.png';
import rblogo from './images/Resume.png';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import bannerImg from './bannerImg5.png'
import "aos/dist/aos.css";
import AOS from 'aos';
import { useEffect } from 'react';




function Banner() {
  useEffect(() => {
    AOS.init({duration:"2000"})
})
  return (
    <>
      <div className='BannerDiv'>
        <div className='BannerPart1'>
          <br />
          <br />
          <br />
          <br />
          <h1 id='Intern1' data-aos="fade-up">Are you Looking For Internship ?</h1>
          <h2 id='Intern2' data-aos="fade-up">Join Our Internship Program </h2>
          <h3 id='Intern3' data-aos="fade-up">Full Stack MERN | Python | Data Analytics</h3>
          <a id='Intern4' data-aos="zoom-in" href='https://wa.link/n3avqx'>Contact Us</a>
        </div>
        <div className='BannerPart1'>
          <img data-aos="zoom-in" src={bannerImg} className='BannerImg' />

        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
      </svg>
    </>
  )
}

function Training() {
  return (
    <>
      <Container id='training' >
        <Row >
          <Col className='mt-5' xs={12} md={6}>
            <Card className='traincard' data-aos="fade-right">
              <Card.Img variant="top" src={Industry} id='trainimg' />
              <Card.Body>
                <Card.Title>Industry-Relevant Training</Card.Title>
                <Card.Text style={{ color: "black", textAlign: "justify" }}>
                  AmpleMind It Institute as well as IT Company as well which is located in Nagpur.
                  We specialize in providing up-to-date and industry-relevant training programs.
                  We design their courses to equip students with the skills and knowledge requied by                   emplyoers in the IT sector.
                </Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='mt-5' xs={12} md={6} >
            <Card className='traincard' data-aos="fade-left">
              <Card.Img variant="top" src={Teaching} id='trainimg' />
              <Card.Body>
                <Card.Title>Experienced and Qualified Trainer</Card.Title>
                <Card.Text style={{ color: "black", textAlign: "justify" }}>
                  AmpleMind often have a team of experienced and qualified instructors who are expert
                  in their respective fields. These instructors bring real-world industry experience to the classroom,
                  enabling them to provide practical insights, guidance and mentorship.
                </Card.Text>

              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='mt-5' xs={12} md={6}>
            <Card data-aos="fade-right">
              <Card.Img variant="top" src={Images} id='trainimg' />
              <Card.Body>
                <Card.Title>Comprehensive Curriculum</Card.Title>
                <Card.Text style={{ color: "black", textAlign: "justify" }}>
                  AmpleMind offer comprehensive and structured curriculum that cover various aspects of IT.
                  We provide a well-rounded education that includes both theoretical knowledge and practical skills.
                </Card.Text>

              </Card.Body>
            </Card>
          </Col>
          <Col className='mt-5' xs={12} md={6}>
            <Card data-aos="fade-left">
              <Card.Img variant="top" src={Placement} id='trainimg' />
              <Card.Body>
                <Card.Title>Placement Assistance</Card.Title>
                <Card.Text style={{ color: "black", textAlign: "justify" }}>
                  We offer 100% placement assistance to their students. We have partnerships with local bussinesses
                  , IT companies and recruitment agencies, which can facilitate job placement opportunities for their graduates.
                </Card.Text>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}



function Tech() {
  return (
    <>
      <Container fluid className='tech-container'>
        <Row>
          <Col className='tech-box'>
            <Row>
              <h1>Top Tech Categories</h1>
              <h4>Gain next-gen tech skills and earn certifications in high-demand fields.</h4>
            </Row>
            <Row>
              <Col className='tech-Courses-mv'>
                <Row className='row1'>
                  <Link className="a" to="/Mernstack">Full-Stack MERN</Link>
                  <Link className="a" to="/Python">Python</Link>
                  <Link className="a" to="/Dataanlytics">Data Anlytics</Link>
                  <Link className="a" to="/Datascience">Data Science</Link>
                  <Link className="a" to="/Java">Java</Link>
                  <Link className="a" to="/Devops">DevOps</Link>
                  <Link className="a" to="">Software Testing</Link>
                  <Link className="a" to="">Node JS</Link>
                  <Link className="a" to="/Salesforce">Salesforce</Link>
                  <Link className="a" to="">Angular</Link>
                  <Link className="a" to="/React">React</Link>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}



function AmpleLearn() {
  return (
    <>
      <Container>
        <Row className='learn'>
          <Col className='learn-left'>
            <h2>Why Learn with AmpleMind?</h2>
            <p style={{ color: "black" }}>
              We believe every learner is an individual and every course is an opportunity to build job-ready skills. Through our human-centered approach to learning,
              we will empower you to fulfil your professional and personal goals and enjoy career success.
            </p>

            <ul>
              <li><h4>Reskilling into tech? We’ll support you.</h4></li>
              <li><h4>Upskilling for promotion? We’ll help you.</h4></li>
              <li><h4>Cross-skilling for your career? We’ll guide you.</h4></li>
            </ul>
          </Col>
          <Col className='learn-right'>

            <div className='table-mv'>
              <Table className='table'>
                <tbody className='body'>
                  <tr>
                    <td><FontAwesomeIcon className='learn-icon' icon={faUser} /></td>
                    <td><h2 className='title'>Personalized Mentoring & Support</h2></td>
                    <td><p style={{ color: "black" }}>1-on-1 mentoring, live classes, webinars, weekly feedback, peer discussion, and much more.</p></td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon className='learn-icon' icon={faFlask} /></td>
                    <td><h2 className='title'>Practical Experience</h2></td>
                    <td><p style={{ color: "black" }}>Hands-on labs and projects tackling real-world challenges. Great for your resumé and LinkedIn profile.</p></td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon className='learn-icon' icon={faStar} /></td>
                    <td><h2 className='title'>Best-in-Class Course Content</h2></td>
                    <td><p style={{ color: "black" }}>Designed by the industry for the industry so you can build job-ready skills.</p></td>
                  </tr>
                  <tr>
                    <td><FontAwesomeIcon className='learn-icon' icon={faChalkboardUser} /></td>
                    <td><h2 className='title'>Choice of Learning Styles</h2></td>
                    <td><p style={{ color: "black" }}>Self-paced and instructor-led options to suit your lifestyle, time, and learning needs.</p></td>
                  </tr>
                </tbody>
              </Table>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text" style={{ color: "black" }}>

      {isReadMore ? text.slice(0, 150) : text}
      <span

        onClick={toggleReadMore}
        className="read-or-hide"
        style={{ color: "blue" }}
      >
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

function Review() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 608,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Container className='review-section' >
        <h1 style={{ color: "black" }}>Our Student's Feedback</h1>
        <Row className='review'>
          <Slider {...settings} className='slider'>
            {data.map((d) => (
              <Col className='review-card'>
                <Row>
                  <Col className='review-img'>
                    <img src={d.img} alt='' className='review-imgs' />
                  </Col>
                </Row>
                <Row>
                  <Col className='review-content'>
                    <p className='review-name' style={{ color: "black" }}>{d.name}</p>
                    <p className='star'>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                    </p>
                    <p className='review-message'><ReadMore>{d.review}</ReadMore></p>
                  </Col>
                </Row>
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
    </>
  );
}


function FreeTraining() {
  return (
    <Container fluid className='free-training'>
      <h2>Free Training</h2>
      <Row>
        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={gdlogo} className='Imgtrain'/>
            <Card.Body>
              <Card.Title style={{textAlign:"center", }}>Group Discussion</Card.Title>


            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={pdlogo} className='Imgtrain'/>
            <Card.Body>
              <Card.Title style={{textAlign:"center"}}>Personality Dev</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={ewlogo} className='Imgtrain'/>
            <Card.Body>
              <Card.Title style={{textAlign:"center"}}>Email Writing</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={islogo} className='Imgtrain' />
            <Card.Body>
              <Card.Title style={{textAlign:"center"}}>Interview Session</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={mtlogo} className='Imgtrain'/>
            <Card.Body>
              <Card.Title style={{textAlign:"center"}}>Mock Test</Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} md={2}>
          <Card className='Cardtrain' data-aos="flip-left">
            <Card.Img variant="top" src={rblogo} className='Imgtrain'/>
            <Card.Body>
              <Card.Title style={{textAlign:"center"}}>Resume Building</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const data = [
  {
    name: `Sakshi Nikhade`,
    img: `student/images/sakshi.jpg`,
    review: `Amplemind  is the best institute , teaching is best, earlier i was not knowing the language properly and also able to code even i don't have interest in coding , when i join this institute i started created interest coding .  The sir is really good and explain with real-time example. i will surely recommend this institute if u want to improve your personal skill and knowledge .`,
  },
  {
    name: `Rahul Nasre`,
    img: `student/images/Rahul.jpeg`,
    review: `💫Amplemind institute one of the best institute in the nagpur city . Amplemind best institute to learn data analysis,full stack,web development and all progrming languages .One of the best institute in town for software courses..☺️best institute to learn booming and demanding technologies, get industry ready with amazing, knowledgeable and experience faculty.☺️☺️`
  },
  {
    name: `Aditi Dewalkar`,
    img: `student/images/aditi.jpeg`,
    review: `"It is a top quality courses. Best tutorial from nagpur. Best teacher to improve our Skills."
    "Well done. Good courses. Excellent tutor"`
  },
  {
    name: `Mithilesh Dudhankar`,
    img: `student/images/mithilesh.jpeg`,
    review: `One of the best IT classes in the location ,Best for non - IT background students . You are the best trainer Sunny sir .`
  },
  {
    name: `Shravan Mate`,
    img: `student/images/shravan.jpg`,
    review: `I recently joined ,the way of teaching is very good and sir personally clear our doubts,
    It is the best out of best in nagpur .`
  },
  {
    name: `Saksham Donge`,
    img: `student/images/saksham.jpg`,
    review: `I haved experience the most exprienced faculty & one of the best Institute`
  },

];



function Home() {
  return (
    <>
      <Banner />
      <Training />
      <Tech />
      <AmpleLearn />
      <FreeTraining />
      <Review />
    </>
  );
}

export default Home;


