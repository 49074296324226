import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./About";
import Home from "./Home";
import Contact from "./Contact";
import Navigation from "./Navbar";
import Mernstack from "./pages/Mernstack";
import Python from "./pages/Python";
import DataAnalytics from "./pages/Dataanalytics";
import DataScience from "./pages/Datascience";
import Java from "./pages/Java";
import DevOps from "./pages/Devops";
import Footer11 from "./Footer11";
import Course from "./Courses";



function Routing() {
    return ( 
        <BrowserRouter>
         <Navigation/>
           <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/Home" element={<Home/>}/>
             <Route path="/courses" element={<Course />}></Route>
             <Route path="/about" element={<About />}></Route>
             <Route path="/contact" element={<Contact />}></Route>
             <Route path="/Mernstack" element={<Mernstack />}></Route>
             <Route path="/Python" element={<Python />}></Route>
             <Route path="/Dataanalytics" element={<DataAnalytics />}></Route>
             <Route path="/Datascience" element={<DataScience />}></Route>
             <Route path="/java" element={<Java />}></Route>
             <Route path="/Devops" element={<DevOps/>}></Route>
           </Routes>
           <Footer11/>
        </BrowserRouter>

     );
}

export default Routing;