import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./Contact.css"
import Logo from './images/contactimagee.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

function Contact() {
    return (
        <>
            <Container fluid className='Contact'>
                <Row className='contact-row'>
                    <Col className='contact-details'> 
                        <img src={Logo} />
                        <h4>Ramna Maroti Rd, above electric Bill Center, near Shiv mandir,
                             Ishwar Nagar, Nirmal Nagar, Nagpur, Maharashtra 440009</h4>
                        <h4>amplemindcontact@gmail.com</h4>
                        <h4>9552211561</h4>
                        <h3>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faFacebook} /></a></span>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faWhatsapp} /></a></span>
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faInstagram} /></a></span> 
                            <span><a><FontAwesomeIcon className='learn-icon' icon={faLinkedin} /></a></span>       

                        </h3>
                    </Col>
                    <Col className='contact-form'>
                        <h2>Get in touch!</h2>

                        <Form>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                              
                                <Form.Control type="text" placeholder="Name" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                        
                                <Form.Control type="number" placeholder="Mobile No." />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                          
                                <Form.Control type="email" placeholder="Email" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="formBasicPassword">
                              
                                <Form.Control type="text" placeholder="Address" />
                            </Form.Group>

                            <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
          
                                <Form.Control as="textarea" rows={3} placeholder='Message' />
                            </Form.Group>

                            <Button className='btn' variant="primary" type="submit" style={{marginLeft:"40%",backgroundColor:"green",fontSize:"18px",color:"white"}}>
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='Contact1'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.650448824958!2d79.12279577343726!3d21.126497684417988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c708d48753cd%3A0xa6f78bb0c556e8d3!2sAmplemind!5e0!3m2!1sen!2sin!4v1711532155143!5m2!1sen!2sin" width="100%" height="470"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>
        </>
    );
}

export default Contact;