import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import "./Mernstack.css";
import devops from '../course_img/devops.png';

function DataScience() {
    return (
        <>

            <Container fluid className='mernMain'>
                <h2>DevOps</h2>


                <Row>
                    <Col className='mern-left' md={4}>
                        <img src={devops} />
                    </Col>

                    <Col className='mern-right' md={8}>

                        <p>

                            DevOps (Development and Operations) is a set of practices that aims to automate and integrate the processes between software development and IT operations teams.
                            Here are some common applications of DevOps:
                        </p>
                        <ul>
                            <h5>1.Continuous Integration (CI): </h5>
                            <li>Automating the process of code integration and testing.
                                Developers regularly commit code to a shared repository, and each commit is verified
                                by an automated build, allowing teams to detect problems early.</li>

                        </ul>
                        <ul>
                            <h5>2.Continuous Delivery (CD):</h5>
                            <li>Automating the deployment of code to production environments.
                                With continuous delivery, every code change that passes automated tests is deployed to production automatically, reducing the time it takes to get feedback on new features.</li>

                        </ul>
                        <ul>
                            <h5>3. Infrastructure as Code (IaC): </h5>
                            <li>Treating infrastructure in a code-like manner, using scripts and configuration files to automate the provisioning and management of infrastructure.
                                This allows for consistent, repeatable deployments and easier scalability.</li>

                        </ul>
                        <ul>
                            <h5>4.Automated Testing:</h5>
                            <li> Automating the testing process to ensure that code changes do not introduce new bugs or regressions.
                                This includes unit tests, integration tests, and end-to-end tests.</li>

                        </ul>
                        <ul>
                            <h5>5.Monitoring and Logging:</h5>
                            <li>Implementing monitoring and logging solutions to track the performance and health of applications and infrastructure in real-time.
                                This allows teams to identify and respond to issues quickly.</li>

                        </ul>
                        <ul>
                            <h5>6. Deployment Orchestration:</h5>
                            <li> Automating the deployment process to ensure that code changes are
                                deployed consistently and reliably across different environments</li>

                        </ul>
                        <ul>
                            <h5>Configuration Management</h5>
                            <li>Managing the configuration of infrastructure and applications using automation tools like Puppet, Chef, or Ansible.
                                This ensures that all environments are configured consistently and reduces the risk of configuration drift.</li>

                        </ul>
                        <ul>
                            <h5>Collaboration and Communication:</h5>
                            <li> Improving collaboration and communication between development, operations,
                                and other teams through the use of tools like Slack, Microsoft Teams, or Jira.</li>

                        </ul>
                        <ul>
                            <h5>Containerization and Orchestration: </h5>
                            <li>Using containerization technologies like Docker and container orchestration
                                platforms like Kubernetes to package and deploy applications in a consistent and scalable way.</li>

                        </ul>

                        <ul>
                            <h5>Security Automation:</h5>
                            <li>Integrating security practices into the development and deployment process,
                                such as automated security testing, vulnerability scanning, and compliance checks.</li>

                        </ul>




                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        <h1 className='syslabusHead'>DevOps Syllabus</h1>
                        <div className='mern-accordian accordion-flush'>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Git and GitHub Training</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Git and GitHub
                                            </li>
                                            <li>Git Basic</li>
                                            <li>Getting started with Git</li>
                                            <li>Remote Repositories</li>
                                            <li>Branching, Merging, and Rebasing in Git</li>
                                            <li> BitBucket and GitLab</li>
                                            <li>GitPlugin with IDE</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>CI/CD Pipeline with Jenkins</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to CI/CD Pipeline with Jenkins</li>
                                            <li>Getting Started with
                                                Jenkins</li>
                                            <li>Build Jobs and
                                                Configurations</li>
                                            <li>Configuring Build Pipelines</li>
                                            <li>Automated Testing In
                                                Jenkins</li>
                                            <li>Code Quality Improvement
                                                Using Jenkins</li>
                                            <li>- Automated Deployment
                                                and Continuous Delivery</li>
                                            <li>Distributed System in
                                                Jenkins</li>
                                            <li></li>
                                            <li></li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>DevOps</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to DevOps</li>
                                            <li>Version Control Systems</li>
                                            <li>Continuous Integration,
                                                Continuous Deployment, and Build
                                                Tools</li>
                                            <li>Software and Automation
                                                Testing Frameworks
                                            </li>
                                            <li>Configuration Management
                                                Tools</li>
                                            <li>Containerization with
                                                Docker</li>
                                            <li>-Continuous Monitoring</li>
                                            <li>-Need of Cloud in DevOps</li>
                                            <li>Practice Projects</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Docker </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Docker </li>
                                            <li>Image Creation, Management, and Registry</li>
                                            <li>Orchestration</li>
                                            <li>Networking</li>
                                            <li>Installation and Configuration of Docker Enterprise</li>
                                            <li>Security</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Kubernetes</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Indentation to Kubernetes</li>
                                            <li>Kubernetes Overview</li>
                                            <li>Setup Kubernetes</li>
                                            <li>Kubernetes Concepts</li>
                                            <li>YAML Introduction</li>
                                            <li>Kubernetes Concepts - PODs, ReplicaSets, Deployments</li>
                                            <li>Networking in Kubernetes</li>
                                            <li>Services</li>
                                            <li>Microservices Architecture</li>
                                            <li>Conclusion</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>DevOps on AWS</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Getting Started with DevOps on AWS Cloud</li>
                                            <li>Spinning Up an IDE in AWS Cloud with Cloud9</li>
                                            <li> Building Applications with AWS CodeBuild</li>
                                            <li>Deploying Applications with AWS CodeDeploy</li>
                                            <li>Automating Deployment with AWS CodePipeline</li>
                                            <li>DevOps with AWS CodeStar</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            






                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>MongoDB</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to MongoDB</li>
                                            <li>MongoDB Installation</li>
                                            <li>Create and Read Operations</li>
                                            <li>ObjectID and BSON</li>
                                            <li>CRUD Operations</li>
                                            <li>UpdateOne and UpdateMany</li>
                                            <li>DeleteOne and DeleteMany</li>
                                            <li>MongoDB Schema</li>
                                            <li>Database Modeling</li>
                                            <li>Relation in Databse MongoDB</li>
                                            <li>MongoDB connection through Mongoose</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>SQL</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to SQL</li>
                                            <li>SQL Data Types</li>
                                            <li>SQL Operators</li>
                                            <li>SQL CRUD Operations</li>
                                            <li>SQL Table</li>
                                            <li>SQL Select</li>
                                            <li>SQL Clause</li>
                                            <li>SQL Order BY</li>
                                            <li>SQL Insert</li>
                                            <li>SQL Update</li>
                                            <li>SQL Delete</li>
                                            <li>SQL Joins</li>
                                            <li>SQL Keys</li>
                                            <li>Project on SQL</li>
                                            <li>Interview Questions on SQL</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Program Projects</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Branching Development Model</li>
                                            <li>Architecting Jenkins Pipeline for Scale</li>
                                            <li>Building a CI/CD Pipeline with Jenkins</li>
                                            <li>Dockerizing Jenkins Pipeline</li>
                                            <li>Deploy Angular Application in Docker Container</li>
                                            <li>Containerizing an Application and Scanning Its Docker Image with DTR</li>
                                            <li>Social Media Underlying Infra Challenges</li>
                                            <li>IT Software Containerization</li>
                                            <li>Hands on with Amazon DynamoDB Database</li>
                                           

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Projects on Python Development</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Static Terminal Projects</li>
                                            <li>Projects like Billing System</li>
                                            <li>Project like ATM</li>
                                            <li>Projects like handling Pharmacy Management Software</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Interview Questions on DevOps</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Interview Questions on DevOps</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>


        </>
    );
}

export default DataScience;

