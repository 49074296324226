import React from 'react';
import "./Footer1.css"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AmpleLogo from './Ample-White-Logoo.png';
import phone from './img/phone.png'

// import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'


function Footer11() {
    return (
        <div className='footMain'>
            <div id='FootCol-1'>

                AmpleMind Provides the Best Software Solutions and Trainings for Job Oriented Courses
                as well as Internships with Certification. Our aim is to provide best career growth to
                the one's who connected to us.
            </div>

            <div id='FootCol2'>
                <a href='https://www.instagram.com/amplemind_/'><p><i class="fa-brands fa-instagram"></i></p></a>
                <a href='https://wa.link/n3avqx'><p><i class="fa-brands fa-whatsapp"></i></p></a>
                <a href=''><p><i class="fa-brands fa-square-facebook"></i></p></a>
                
            </div>
            <div id="FootCol1">
                <table>
                    <tr>
                        <td>
                      

                        </td>
                        <td><h4>Ramna Maroti Rd, above electric Bill Center, near Shiv mandir,
                            Ishwar Nagar, Nirmal Nagar, Nagpur, Maharashtra 440009</h4></td>
                    </tr>
                    <tr>
                        <td>
                            
                        </td>
                        <td>
                            <h4>amplemindcontact@gmail.com</h4>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            
                        </td>
                        <td>
                            <h4>9552211561</h4>
                        </td>
                    </tr>
                </table>
            </div>
            
            

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='wave'>
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
            </svg>

            
        </div>
    )
}

export default Footer11;