import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import "./Mernstack.css";
import da from '../course_img/data_analytics.png';

function DataAnalytics() {
    return (
        <>
            <Container fluid className='mernMain'>
                <h2>Data Analytics</h2>

                <Row>
                    <Col className='mern-left' md={4}>
                        <img src={da} />
                    </Col>

                    <Col className='mern-right' md={8}>

                        <p>

                            Data analytics is a field that involves analyzing, interpreting,
                            and visualizing data to extract valuable insights and make data-driven decisions.
                            Python is one of the most popular programming languages for data analytics due to its simplicity,
                            readability, and the availability of powerful libraries and frameworks.
                        </p>
                        <ul>
                            <h5>1. Data Cleaning and Preprocessing:</h5>
                            <li>Pandas: Python's Pandas library is widely used for data manipulation and analysis. It provides data structures and functions to clean, transform, and preprocess data.</li>
                            
                            

                        </ul>
                        <ul>
                            <h5>2. Data Visualization:</h5>
                            <li>Matplotlib: A 2D plotting library that produces publication-quality figures.</li>
                            <li>Seaborn: A statistical data visualization library built on top of Matplotlib, providing a high-level interface 
                            for drawing attractive and informative statistical graphics.</li>
                            <li>Plotly: A graphing library that makes interactive, publication-quality graphs online.</li>

                        </ul>
                        <ul>
                            <h5>3.Statistical Analysis:</h5>
                            <li>SciPy: A library for scientific and technical computing that includes modules for optimization, integration, interpolation, linear algebra, and more.</li>
                            <li>Statsmodels: A library for estimating and interpreting statistical models..</li>

                        </ul>
                        <ul>
                            <h5>4. Big Data Processing:</h5>
                            <li>Dask: A flexible library for parallel computing in Python that enables scalable data processing.</li>
                            <li>Apache Spark: Python APIs (PySpark) are available for Apache Spark, a fast and general-purpose cluster computing system for big data processing.</li>

                        </ul>
                        <ul>
                            <h5>5. Data Mining and Web Scraping:</h5>
                            <li>Beautiful Soup: A Python library for pulling data out of HTML and XML files.</li>
                            <li>Scrapy: A fast high-level web crawling and web scraping framework.</li>

                        </ul>
                        <ul>
                            <h5>6. Business Intelligence (BI):</h5>
                            <li>
                            Power BI and Tableau: Python can be used to extract, clean, and preprocess data for visualization in BI tools.
                            </li>

                        </ul>
                       

                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        <h1 className='syslabusHead'>Data Analytics</h1>
                        <div className='mern-accordian accordion-flush'>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Introduction to Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>What is Python programming language</li>
                                            <li>Advantages and applications of Python</li>
                                            <li>Installation of Python</li>
                                            <li>Python Keywords</li>
                                            <li>The statement, Indentation, and Comment in Python</li>
                                            <li>Rule of Variables in Python</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Input / Output</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Taking input</li>
                                            <li>Output Using print() function</li>
                                            <li>Output Formatting</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Operators</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Operators in Python</li>
                                            <li>Types of operators</li>
                                            <li>Arithmetic operator</li>
                                            <li>Assignment operator</li>
                                            <li>Comparison operator</li>
                                            <li>Logical operator</li>
                                            <li>Bitwise operator</li>
                                            <li>Special operators</li>
                                            <li>Ternary Operator</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Data Types</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Python String</li>
                                            <li>Numbers</li>
                                            <li>Python String</li>
                                            <li>Python List</li>
                                            <li>Python Tuples</li>
                                            <li>Python Sets</li>
                                            <li>Python Dictionary</li>
                                            <li>Type Conversion</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Control Panel</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Python Conditional statements</li>
                                            <li>Python For Loop</li>
                                            <li>Python While Loop</li>
                                            <li>Python break statement</li>
                                            <li>Python continue statement</li>
                                            <li>Python pass statement</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Strings</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Access characters/elements in a string</li>
                                            <li>Concatenation of two or more string</li>
                                            <li>String Slicing</li>
                                            <li>Iterating string</li>
                                            <li>Delete or Change String</li>
                                            <li>String Formatting</li>
                                            <li>String Methods</li>
                                            <li>Escape Characters</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Lists in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Knowing the size of the list</li>
                                            <li>Adding elements to a list</li>
                                            <li>Accessing elements from the list</li>
                                            <li>Delete or Remove elements from the list</li>
                                            <li>Slicing of a list</li>
                                            <li>Iterating a list</li>
                                            <li>List Comprehension</li>
                                            <li>Nested list</li>
                                            <li>List Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Tuples</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Accessing elements in a Tuple</li>
                                            <li>Tuple Slicing</li>
                                            <li>Update Tuple</li>
                                            <li>Unpack Tuple</li>
                                            <li>Concatenation of Tuples</li>
                                            <li>Changing and deleting Tuples</li>
                                            <li>Python Tuple Methods</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Dictionary in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Accessing Elements from a Dictionary</li>
                                            <li>Add/Change Elements in a Dictionary</li>
                                            <li>Nested Dictionary</li>
                                            <li>Deleting Elements from Dictionary</li>
                                            <li>Dictionary Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Sets in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Adding Elements to a Set</li>
                                            <li>Accessing a Set</li>
                                            <li>Removing Elements from a Set</li>
                                            <li>Set Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Functions</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Syntax of Function</li>
                                            <li>Types of Functions</li>
                                            <li>Arguments</li>
                                            <li>Recursion Function</li>
                                            <li>Recursive Function</li>
                                            <li>Lambda functions</li>
                                            <li>Modules in python</li>
                                            <li>Packages</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>OOP in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Class</li>
                                            <li>Object</li>
                                            <li>Inheritance</li>
                                            <li>Iterators</li>
                                            <li>Encapsulation</li>
                                            <li>Polymorphism</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Exception Handling in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Syntax Error</li>
                                            <li>Try and Except in Exception Handling</li>
                                            <li>Python Errors and Built-in Exceptions</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>File Handling </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Open a File </li>
                                            <li>Close a file </li>
                                            <li>write a python </li>
                                            <li>Read Files</li>
                                            <li>Python Directory</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            



                            
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Data Analysis with Pandas</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Jupyter Notebook</li>
                                            <li>Installing Jupyter Notebook</li>
                                            <li>How to use Jupyter Notebook?</li>
                                            <li>Introduction to Pandas</li>
                                            <li>Installing Pandas</li>
                                            <li>Pandas Series</li>
                                            <li>Coding: Pandas Series</li>
                                            <li>DataFrames I- Working with Columns</li>
                                            <li>Pandas DataFrames- Working with Rows and Columns</li>
                                            <li>Pandas DataFrames: Filtering Data</li>
                                            <li>Coding: Filtering Data</li>
                                            <li>Reading and Analysing CSV Files with Pandas</li>
                                            <li>Coding: Reading and Analysing CSV Files</li>
                                            <li>Reading Excel Files: GroupBy and Other Useful Operations</li>
                                            <li>Working with Missing Data</li>
                                            <li>Coding: Working with Missing Data</li>
                                            <li>Test Your Knowledge: Pandas</li>
                                           
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Numpy</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to NumPy</li>
                                            <li>1-D Array</li>
                                            <li>2-D Array</li>
                                            <li>Indexing</li>
                                            <li>slicing</li>
                                            <li>Concatenating</li>
                                            <li>Broadcasting</li>
                                            <li>Statistical functions</li>
                                            <li>Arithmetic functions</li>
                                           
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Matplotlib</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Matplotlib</li>
                                            <li>Matplotlib pyplot</li>
                                            <li>Matplotlib Markers</li>
                                            <li>Matplotlib Line</li>
                                            <li>Matplotlib Labels</li>
                                            <li>Matplotlib Grid </li>
                                            <li>Matplotlib Subplot</li>
                                            <li>Matplotlib Scatter</li>
                                            <li>Matplotlib Bars</li>
                                            <li>Matplotlib Histogram</li>
                                            <li>Matplotlib Pie Charts</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Data visualization using Seaborn</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Python Visualization Libraries</li>
                                            <li>Installing Plotly</li>
                                            <li>Creating Scatter Plots</li>
                                            <li>Creating Line Charts</li>
                                            <li>Line Charts</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Power BI</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Power BI</li>
                                            <li>PowerBI Dashboard</li>
                                            <li>Connectors</li>
                                            <li>Basic Transformations in Power BI</li>
                                            <li>Format Tool</li>
                                            <li>Dealing with text tools</li>
                                            <li>Dealing with Numerical Tools</li>
                                            <li>Create a Table Using Power BI</li>
                                            <li>Dealing with Date and Time</li>
                                            <li>Pivoting and Unpivoting of data</li>
                                            <li>Adding Conditional Columns</li>
                                            <li>Merge queries and Append queries</li>
                                            <li>Data model and importance of Data Modeling</li>
                                            <li>Manage Data Relationships</li>
                                            <li>Editing a Relationship</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>DAX Functions in Power BI</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to DAX</li>
                                            <li>Importance of DAX in Power BI</li>
                                            <li>DAX Expressions</li>
                                            <li>Steps to Create Calculated Columns in DAX</li>
                                            <li>Creation of Measures in Power BI and its types</li>
                                            <li>Understanding DAX syntax in Power BI</li>
                                            <li>DAX Functions in Power BI</li>
                                            <li>Date and Time Functions</li>
                                            <li>Text Functions Using DAX</li>
                                            <li>Logical Functions Using DAX</li>
                                            <li>DAX Operators</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Dashboard creation in Power BI</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Visuals in Power BI</li>
                                            <li>Visualisation charts in Power BI</li>
                                            <li>Filtering Options</li>
                                            <li>Exploring Matrix Visuals</li>
                                            <li>Filtering Data with Slicers</li>
                                            <li>Number Cards and Text Cards</li>
                                            <li>KPI Visuals</li>
                                            <li>Modifying Colours in Charts And Visuals</li>
                                            <li>Visualizing Data with Maps</li>
                                            <li>Tree Map</li>
                                            <li>Bookmarks and Buttons</li>
                                            <li>Designing for Phone vs Desktop Report Viewers</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Advance Excel for Data Analysis</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to MS Excel</li>
                                            <li>Basic Functions of Excel</li>
                                            <li>Sorting Data</li>
                                            <li>Filtering Data</li>
                                            <li>Basics of Formula/Functions</li>
                                            <li>If Functions and text Functions</li>
                                            <li>Date & Time Formula</li>
                                            <li>COUNTIF, COUNTIFS, SUMIF, SUMIFS</li>
                                            <li>Lookup and Information Functions</li>
                                            <li>Using Conditional Formatting</li>
                                            <li>Macros</li>
                                            <li>Summarising Data with Pivot Tables</li>
                                            <li>Working with Slicers</li>
                                            <li>Power Pivot / Power Query</li>
                                            <li>Charts</li>
                                            <li>Excel For Data Analytics</li>
                                            <li>Data Visualization with Excel</li>
                                            <li>Excel Dashboard </li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>MongoDB</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to MongoDB</li>
                                            <li>MongoDB Installation</li>
                                            <li>Create and Read Operations</li>
                                            <li>ObjectID and BSON</li>
                                            <li>CRUD Operations</li>
                                            <li>UpdateOne and UpdateMany</li>
                                            <li>DeleteOne and DeleteMany</li>
                                            <li>MongoDB Schema</li>
                                            <li>Database Modeling</li>
                                            <li>Relation in Databse MongoDB</li>
                                            <li>MongoDB connection through Mongoose</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>SQL</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to SQL</li>
                                            <li>SQL Data Types</li>
                                            <li>SQL Operators</li>
                                            <li>SQL CRUD Operations</li>
                                            <li>SQL Table</li>
                                            <li>SQL Select</li>
                                            <li>SQL Clause</li>
                                            <li>SQL Order BY</li>
                                            <li>SQL Insert</li>
                                            <li>SQL Update</li>
                                            <li>SQL Delete</li>
                                            <li>SQL Joins</li>
                                            <li>SQL Keys</li>
                                            <li>Project on SQL</li>
                                            <li>Interview Questions on SQL</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Projects on Data Analytics</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Data Mining</li>
                                            <li>Project Report on Data Analytics</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Interview Questions on Data Analytics</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Interview Questions on Data Analytics</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>


        </>
    );
}

export default DataAnalytics;

