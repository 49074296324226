import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css';
import Vision from './img/pic2.jpg'
import Mission from './images/Technology.jpg'


const AboutUs = () => {
    return (
        <>
            <Container fluid className='about-us-container'>
                <h1 className='Abouthead'>Welcome to AmpleMind</h1>
                <Row className='vision'>
                    <Col xs={12} md={6} className='vision-left' >
                        <h2 style={{color:"#37a113"}}> Our Vision</h2>
                        <p style={{ textAlign: 'justify', fontSize: '20px', color: 'white' }}>
                            Our vision at AmpleMind is to become a globally recognized leader
                            in IT education and software solutions. We strive to empower individuals
                            and organizations with the knowledge, skills, and technology they need
                            to thrive in the digital era. By delivering exceptional training programs
                            and innovative software services, we aim to be the go-to destination for
                            IT professionals and businesses seeking to unlock their full potential.
                        </p>
                    </Col>
                    <Col xs={12} md={6} className='vision-right'>
                        <img src={Vision} alt='Vision Image' />
                    </Col>
                </Row>
                <Row className='Mission'>
                    <Col xs={12} md={6} className='Mission-left'>
                        <img src={Mission} alt='Mission Image' />
                    </Col>
                    <Col xs={12} md={6} className='Mission-right'>
                        <h2 style={{color:"#37a113"}}> Our Mission</h2>
                        <p style={{ textAlign: 'justify', fontSize: '20px', color: 'white' }}>
                            Our mission is to provide top-quality IT education and training that
                            enables individuals to build successful careers in the ever-evolving
                            field of technology. We are committed to offering comprehensive and
                            industry-relevant courses, led by experienced instructors, to equip
                            our students with the skills and expertise demanded by the IT industry.
                            Through our practical, hands-on approach, we aim to foster a learning
                            environment that encourages creativity, critical thinking, and problem-solving
                            abilities. Our ultimate goal is to empower our students to excel in
                            their chosen IT careers and contribute to the advancement of the digital world.
                        
                        </p>
                    
                    </Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>
        </>
    );
};

export default AboutUs;
