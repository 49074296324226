import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/logo.png';
import './Navbar.css'


function Navigation() {
  return (
    <Navbar expand="lg"  bg="black" data-bs-theme="dark" >
    <Container fluid style={{backgroundColor:"black"}}>
    <Navbar.Brand href="/home">
            <img
              alt=""
              src={logo}
              style={{width:"200px", height:"50px"}}
              className="d-inline-block align-top"
            />{' '}
           
          </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse  style={{marginLeft:"50%"}}>
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '180px' }}
    
        >
          <Nav.Link href="/home" className='NavLink'>Home</Nav.Link>
          <Nav.Link href="/courses" className='NavLink'>Courses</Nav.Link>
          <Nav.Link href="/about" className='NavLink'>About</Nav.Link>
          <Nav.Link href="/contact" className='NavLink'>Contact</Nav.Link>         
          
        </Nav>       
      </Navbar.Collapse>
    </Container>
  </Navbar>

    );
}

export default Navigation;

