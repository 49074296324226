import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import "./Mernstack.css";
import ds from '../course_img/data_science.png';

function DataScience() {
    return (
        <>

            <Container fluid className='mernMain'>
                <h2>Course on Data Science</h2>


                <Row>
                    <Col className='mern-left' md={4}>
                        <img src={ds} />
                    </Col>

                    <Col className='mern-right' md={8}>

                        <p>
                            Python is a versatile programming language used in various fields due to its simplicity, flexibility,
                            and wide range of libraries and frameworks
                        </p>
                        <ul>
                            <h5>1. Data Science and Machine Learning:</h5>
                            <li>NumPy: A library for numerical computing, providing support for large,
                                multi-dimensional arrays and matrices</li>
                            <li>
                                Pandas: A library for data manipulation and analysis, offering data structures and operations
                                for manipulating numerical tables and time series
                            </li>
                            <li>
                                Matplotlib and Seaborn: Libraries for data visualization.
                            </li>

                        </ul>
                        <ul>
                            <h5>2. Game Development:</h5>
                            <li>Pygame: A set of Python modules designed for writing video games.</li>

                        </ul>
                        <ul>
                            <h5>Desktop GUI Applications:</h5>
                            <li>Tkinter: The standard Python interface to the Tk GUI toolkit.</li>
                            <li>PyQt and PySide: Python bindings for the Qt application framework.</li>

                        </ul>
                        <ul>
                            <h5>Scripting and Automation:</h5>
                            <li>Python is widely used for writing automation scripts,
                                handling file operations, and scheduling tasks.</li>

                        </ul>
                        <ul>
                            <h5>Artificial Intelligence (AI) </h5>
                            <li >libraries such as Matplotlib, Seaborn, and Plotly are commonly used for creating visualizations from data.</li>

                        </ul>
                        <ul>
                            <h5>Finance and Trading:</h5>
                            <li>Python is used for financial modeling, algorithmic trading, and quantitative analysis
                                due to libraries like Pandas, NumPy, and Quantlib.</li>

                        </ul>
                        <ul>
                            <h5>Web Development:</h5>
                            <li>Django: A high-level web framework that encourages rapid development and clean, pragmatic design.</li>
                            <li>Flask: A lightweight WSGI web application framework, perfect for small to medium-sized web applications.</li>
                        </ul>

                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        <h1 className='syslabusHead'>Syllabus on DS</h1>
                        <div className='mern-accordian accordion-flush'>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Introduction to Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>What is Python programming language</li>
                                            <li>Advantages and applications of Python</li>
                                            <li>Installation of Python</li>
                                            <li>Python Keywords</li>
                                            <li>The statement, Indentation, and Comment in Python</li>
                                            <li>Rule of Variables in Python</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Input / Output</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Taking input</li>
                                            <li>Output Using print() function</li>
                                            <li>Output Formatting</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Operators</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Operators in Python</li>
                                            <li>Types of operators</li>
                                            <li>Arithmetic operator</li>
                                            <li>Assignment operator</li>
                                            <li>Comparison operator</li>
                                            <li>Logical operator</li>
                                            <li>Bitwise operator</li>
                                            <li>Special operators</li>
                                            <li>Ternary Operator</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Data Types</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Python String</li>
                                            <li>Numbers</li>
                                            <li>Python String</li>
                                            <li>Python List</li>
                                            <li>Python Tuples</li>
                                            <li>Python Sets</li>
                                            <li>Python Dictionary</li>
                                            <li>Type Conversion</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Control Panel</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Python Conditional statements</li>
                                            <li>Python For Loop</li>
                                            <li>Python While Loop</li>
                                            <li>Python break statement</li>
                                            <li>Python continue statement</li>
                                            <li>Python pass statement</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Strings</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Access characters/elements in a string</li>
                                            <li>Concatenation of two or more string</li>
                                            <li>String Slicing</li>
                                            <li>Iterating string</li>
                                            <li>Delete or Change String</li>
                                            <li>String Formatting</li>
                                            <li>String Methods</li>
                                            <li>Escape Characters</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Lists in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Knowing the size of the list</li>
                                            <li>Adding elements to a list</li>
                                            <li>Accessing elements from the list</li>
                                            <li>Delete or Remove elements from the list</li>
                                            <li>Slicing of a list</li>
                                            <li>Iterating a list</li>
                                            <li>List Comprehension</li>
                                            <li>Nested list</li>
                                            <li>List Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Tuples</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Accessing elements in a Tuple</li>
                                            <li>Tuple Slicing</li>
                                            <li>Update Tuple</li>
                                            <li>Unpack Tuple</li>
                                            <li>Concatenation of Tuples</li>
                                            <li>Changing and deleting Tuples</li>
                                            <li>Python Tuple Methods</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Dictionary in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Accessing Elements from a Dictionary</li>
                                            <li>Add/Change Elements in a Dictionary</li>
                                            <li>Nested Dictionary</li>
                                            <li>Deleting Elements from Dictionary</li>
                                            <li>Dictionary Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Sets in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Adding Elements to a Set</li>
                                            <li>Accessing a Set</li>
                                            <li>Removing Elements from a Set</li>
                                            <li>Set Methods</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Functions</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Syntax of Function</li>
                                            <li>Types of Functions</li>
                                            <li>Arguments</li>
                                            <li>Recursion Function</li>
                                            <li>Recursive Function</li>
                                            <li>Lambda functions</li>
                                            <li>Modules in python</li>
                                            <li>Packages</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>OOP in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Class</li>
                                            <li>Object</li>
                                            <li>Inheritance</li>
                                            <li>Iterators</li>
                                            <li>Encapsulation</li>
                                            <li>Polymorphism</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Exception Handling in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Syntax Error</li>
                                            <li>Try and Except in Exception Handling</li>
                                            <li>Python Errors and Built-in Exceptions</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>File Handling </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Open a File </li>
                                            <li>Close a file </li>
                                            <li>write a python </li>
                                            <li>Read Files</li>
                                            <li>Python Directory</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Introduction to Data Science</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Overview of AI, ML, and DS</li>
                                            <li>Understanding the various applications of Data Science</li>
                                            <li>Different sectors using Data Science</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Statistics</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Statistics</li>
                                            <li>Understanding data, sample, and population</li>
                                            <li>Types of data– Qualitative and Quantitative</li>
                                            <li>Descriptive Statistics</li>
                                            <li>Uni-variate Data Analysis– Measure of Central Tendency</li>
                                            <li>Mean, Median and Mode</li>
                                            <li>Uni-variate Data Analysis– Measure of Dispersion</li>
                                            <li>Range, Variance, Standard Deviation</li>
                                            <li>Bi-variate Data Analysis– Covariance and Correlation</li>
                                            <li>Inferential Statistics</li>
                                            <li>Central Limit Theorem</li>
                                            <li>Random Variable and different types of random variable</li>
                                            <li>Probability Distribution Functions</li>
                                            <li>Normal Distribution</li>
                                            <li>Binomial and Poisson Distributions</li>
                                            <li>Skewness and different types of skewness</li>
                                            <li>What is Hypothesis Testing?</li>
                                            <li>Null and Alternate Hypothesis</li>
                                            <li>P-value, Level of significance</li>
                                            <li>Confidence Level and Confidence Interval</li>
                                            <li>One Sample Z-test</li>
                                            <li>learner’s T-test</li>
                                            <li>Chi Square Test</li>
                                            <li>Exercise– Statistics</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>NumPy</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to NumPy</li>
                                            <li>Features of NumPy</li>
                                            <li>Create NumPy Array</li>
                                            <li>Different ways to create NumPy array</li>
                                            <li>Numpy Custom Array Creation using zeros, ones, linspace, etc.</li>
                                            <li>NumPy Array Indexing</li>
                                            <li>NumPy 1D, 2D, and 3D Indexing</li>
                                            <li>NumPy slicing</li>
                                            <li>NumPy advanced indexing and slicing</li>
                                            <li>Generating NumPy arrays with random values</li>
                                            <li>NumPy Array Broadcasting</li>
                                            <li>NumPy Array Iterating</li>
                                            <li>NumPy Array Manipulation</li>
                                            <li>NumPy Arithmetic Operation</li>
                                            <li>NumPy Statistical Function</li>
                                            <li>numpy.amin() and numpy.amax()</li>
                                            <li>numpy.ptp(), numpy.percentile()</li>
                                            <li>numpy.median(), numpy.mean()</li>
                                            <li>numpy.average(), Standard Deviation</li>
                                            <li>Variance</li>
                                            <li>NumPy Random</li>
                                            <li>What is Random Number</li>
                                            <li>Generate Random Number</li>
                                            <li>Generate Random Float</li>
                                            <li>Generate Random Array</li>
                                            <li>Generate Random Number From Array</li>
                                            <li>Random Data Distribution</li>
                                            <li>What is Data Distribution</li>
                                            <li>Random Distribution</li>
                                            <li>Random Permutations</li>
                                            <li>Random Permutations of Elements</li>
                                            <li>Shuffling Arrays</li>
                                            <li>Generating Permutation of Arrays</li>
                                            <li>Seaborn</li>
                                            <li>Visualize Distributions With Seaborn</li>
                                            <li>Distplots</li>
                                            <li>Import Matplotlib</li>
                                            <li>Import Seaborn</li>
                                            <li>Import Seaborn</li>
                                            <li>Plotting a Distplot Without the Histogram</li>
                                            <li>Normal (Gaussian) Distribution</li>
                                            <li>Normal Distribution</li>
                                            <li>Visualization of Normal Distribution</li>
                                            <li>Binomial Distribution</li>
                                            <li>Visualization of Binomial Distribution</li>
                                            <li>Difference Between Normal and Binomial Distribution</li>
                                            <li>Poisson Distribution</li>
                                            <li>Visualization of Poisson Distribution</li>
                                            <li>Difference Between Normal and Poisson Distribution</li>
                                            <li>Difference Between Poisson and Binomial Distribution</li>
                                            <li>Uniform Distribution</li>
                                            <li>Visualization of Uniform Distribution</li>
                                            <li>Logistic Distribution</li>
                                            <li>Visualization of Logistic Distribution</li>
                                            <li>Difference Between Logistic and Normal Distribution</li>
                                            <li>Multinomial Distribution</li>
                                            <li>Exponential Distribution</li>
                                            <li>Visualization of Exponential Distribution</li>
                                            <li>Relation Between Poisson and Exponential Distribution</li>
                                            <li>Chi Square Distribution</li>
                                            <li>Visualization of Chi Square Distribution</li>
                                            <li>Rayleigh Distribution</li>
                                            <li>Visualization of Rayleigh Distribution</li>
                                            <li>Similarity Between Rayleigh and Chi Square Distribution</li>
                                            <li>Pareto Distribution</li>
                                            <li>Visualization of Pareto Distribution</li>
                                            <li>Zipf Distribution</li>
                                            <li>Visualization of Zipf Distribution</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Pandas</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Pandas</li>
                                            <li>Understanding Series in Pandas</li>
                                            <li>Creating Series using– NumPy array, list, tuple, from a .csv/excel file</li>
                                            <li>Series methods– mean, sum, count, etc.</li>
                                            <li>Series indexing and slicing using– iloc and loc</li>
                                            <li>Reading a .csv, .excel files using Pandas– read_csv, read_excel</li>
                                            <li>Understanding DataFrame in Pandas</li>
                                            <li>Creating DataFrame using NumPy array, list, tuple, from a .csv/excel file</li>
                                            <li>Head, tail, and sample methods for DataFrame</li>
                                            <li>DataFrame indexing and slicing using– iloc and loc</li>
                                            <li>Accessing column values from a DataFrame</li>
                                            <li>Set DataFrame index, sort index, and values</li>
                                            <li>DataFrame query</li>
                                            <li>Find unique values for a column in DataFrame</li>
                                            <li>Group by method</li>
                                            <li>Data wrangling methods I– merge, append, concat</li>
                                            <li>Data wrangling methods II– map, apply, applymap</li>
                                            <li>Data cleansing I– rename columns, rearrange columns</li>
                                            <li>Data cleansing II– remove null values, fill null values</li>
                                            <li>Data cleansing III– drop rows, drop columns</li>
                                            <li>Handling datetime in Pandas</li>
                                            <li>Pivot table</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Python Matplotlib</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Matplotlib</li>
                                            <li>Matplotlib pyplot</li>
                                            <li>Matplotlib Markers</li>
                                            <li>Matplotlib Line</li>
                                            <li>Matplotlib Labels</li>
                                            <li>Matplotlib Grid </li>
                                            <li>Matplotlib Subplot</li>
                                            <li>Matplotlib Scatter</li>
                                            <li>Matplotlib Bars</li>
                                            <li>Matplotlib Histogram</li>
                                            <li>Matplotlib Pie Charts</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Seaborn and Plotly</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Seaborn visualization</li>
                                            <li>Countplot</li>
                                            <li>Boxplot</li>
                                            <li>Violinplot</li>
                                            <li>Pairplot</li>
                                            <li>Heatmap</li>
                                            <li>Scatterplot</li>
                                            <li>Plotting Geospatial maps using Plotly</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Machine Learning</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Machine Learning</li>
                                            <li>Understanding different types of Learning– Supervised and Unsupervised Learning</li>
                                            <li>Understanding Supervised and Unsupervised algorithms</li>
                                            <li>Difference between Supervised and Unsupervised Learning</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Linear Regression</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Splitting data into training and test datasets</li>
                                            <li>Understanding the working and equation of Regression Analysis</li>
                                            <li>Regression metrics– R2-score, MAE, MSE, RMSE</li>
                                            <li>Implementation of Simple Linear Regression</li>
                                            <li>Implementation of Multiple Linear Regression</li>
                                            <li>Project– Heating and Cooling Load Prediction</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Clasification Metrics</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding Confusion Matrix</li>
                                            <li>Understanding the concept of True positive, False Positive, True</li>
                                            <li>Negative and False Negative</li>
                                            <li>Classification Metrics– Accuracy, Precision, Recall, F1-Score</li>
                                            <li>Bias Variance, Underfitting, and Overfitting</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Logistic Regression</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of Logistic Regression</li>
                                            <li>Derivation of Sigmoid function</li>
                                            <li>Implementation of Logistic Regression</li>
                                            <li>Project– Diabetic patient Classification</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>KNN Classification</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of KNN</li>
                                            <li>Algorithm of KNN</li>
                                            <li>Implementation of KNN</li>
                                            <li>Project– Social Network Ads Classification</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Tree Based Models</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of Decision Tree</li>
                                            <li>Understanding Gini and Entropy criterion</li>
                                            <li>Implementation of Decision Tree Classification</li>
                                            <li>Understanding the working of Random Forest Classification</li>
                                            <li>Concept of Bootstrapping</li>
                                            <li>Implementation of Random Forest Classification</li>
                                            <li>Project– Iris Flower Classification</li>
                                            <li>Project– Placement Prediction</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Naive Based Classification</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of Naive Bayes</li>
                                            <li>Implementation of Naive Bayes Classification</li>
                                            <li>Project– News Classification</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>K- Means Clustering</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of K-Means Clustering</li>
                                            <li>Understanding of Elbow method to find the optimal number of clusters</li>
                                            <li>Implementation of K-Means Clustering</li>
                                            <li>Project– Shopping dataset Clustering</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>PCA</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding the working of PCA</li>
                                            <li>Understanding Eigen values and Eigen vectors</li>
                                            <li>Implementation of PCA</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Boosting Algorithm</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Difference between Bagging and Boosting</li>
                                            <li>Understanding working of AdaBoost</li>
                                            <li>Implementation of AdaBoost</li>
                                            <li>Understanding working of XGBoost</li>
                                            <li>Implementation of XGBoost</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Natural Language Processing</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to NLP</li>
                                            <li>Removing Stop Words, Stemming, Lemmatization</li>
                                            <li>Count Vectorizer and Tf-Idf</li>
                                            <li>Project– Spam vs. ham Email Classification</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Image Processing using OpenCV</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Reading and displaying an image using OpenCV</li>
                                            <li>Image Transformation operations</li>
                                            <li>Filtering and Thresholding</li>
                                            <li>Erosion and Dilation</li>
                                            <li>Object Detection using Haar Cascade Files– Face and car Detection</li>
                                            <li>Project– Clustering colors in images</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Deep Learning, AI and Neutral Networks</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Neural Network</li>
                                            <li>What is a Neuron?</li>
                                            <li>Working of a Neuron</li>
                                            <li>Perceptron Model</li>
                                            <li>Concept of Hidden layers and Weights</li>
                                            <li>Concept of Activation Functions, Optimizers, and Loss Functions</li>
                                            <li>Equation of a General Neural Network</li>
                                            <li>Understanding Backpropagation</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>TensorFlow</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to TensorFlow</li>
                                            <li>Importing TensorFlow</li>
                                            <li>Using TensorFlow on Colab</li>
                                            <li>What is a tensor?</li>
                                            <li>Indexing and Slicing</li>
                                            <li>Tensorflow basic operations</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Activation and Loss Functions and Optimizers</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding different Activation Functions</li>
                                            <li>Linear, Sigmoid, Tanh, Relu</li>
                                            <li>Understanding different Loss Functions</li>
                                            <li>MSE, Binary CrossEntropy, etc.</li>
                                            <li>Understanding different Optimizers</li>
                                            <li>Gradient Descent, Adam, etc.</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>ANN</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Implementation of a Neural Network</li>
                                            <li>Implementation of ANN for Regression</li>
                                            <li>Implementation of ANN for Classification</li>
                                            <li>Project– Customer Churn Modelling</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>CNN</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Understanding CNN (Convolutional Neural Network)</li>
                                            <li>Understanding the Convolution process</li>
                                            <li>Concept of Filter, strides</li>
                                            <li>Pooling Layer</li>
                                            <li>Fully Connected Layer</li>
                                            <li>Project– MNIST Image Classification</li>
                                            <li>Project– Fashion MNIST Image Classification</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Deep Learning Projects</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>MNIST Image Classification</li>
                                            <li>Fashion MNIST Image Classification</li>
                                            <li>Customer Churn Modelling</li>
                                            <li>Spam vs Ham Email Classification</li>
                                            <li>HR Analytics Classification</li>
                                            <li>Big mart Sales Prediction</li>
                                            <li>Bank Loan Prediction</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            





                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>MongoDB</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to MongoDB</li>
                                            <li>MongoDB Installation</li>
                                            <li>Create and Read Operations</li>
                                            <li>ObjectID and BSON</li>
                                            <li>CRUD Operations</li>
                                            <li>UpdateOne and UpdateMany</li>
                                            <li>DeleteOne and DeleteMany</li>
                                            <li>MongoDB Schema</li>
                                            <li>Database Modeling</li>
                                            <li>Relation in Databse MongoDB</li>
                                            <li>MongoDB connection through Mongoose</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>SQL</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to SQL</li>
                                            <li>SQL Data Types</li>
                                            <li>SQL Operators</li>
                                            <li>SQL CRUD Operations</li>
                                            <li>SQL Table</li>
                                            <li>SQL Select</li>
                                            <li>SQL Clause</li>
                                            <li>SQL Order BY</li>
                                            <li>SQL Insert</li>
                                            <li>SQL Update</li>
                                            <li>SQL Delete</li>
                                            <li>SQL Joins</li>
                                            <li>SQL Keys</li>
                                            <li>Project on SQL</li>
                                            <li>Interview Questions on SQL</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Tkinter in Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Tkinter</li>
                                            <li>First GUI application in Tkinter</li>
                                            <li>Tkinter Frame Widget</li>
                                            <li>Tkinter Labels</li>
                                            <li>Tkinter Buttons</li>
                                            <li>Tkinter Entry</li>
                                            <li>Tkinter CheckBox and CheckButtons</li>
                                            <li>Tkinter ListBox</li>
                                            <li>Tkinter MessageBox</li>
                                            <li>Geometry Management of Tkinter</li>
                                            <li>Binding Functions</li>
                                            <li>Working with connection of TKinter</li>
                                            <li>Projects on TKinter</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Numpy Introduction</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Numpy Introduction</li>
                                            <li>Applications of Numpy</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Pandas Introduction</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Pandas</li>
                                            <li>Application of Pandas</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Projects on Python Development</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Static Terminal Projects</li>
                                            <li>Projects like Billing System</li>
                                            <li>Project like ATM</li>
                                            <li>Projects like handling Pharmacy Management Software</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Interview Questions on Python</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Interview Questions on Python</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>


        </>
    );
}

export default DataScience;

