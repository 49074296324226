import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import "./Mernstack.css";
import java from '../course_img/java.png';

function Java() {
    return (
        <>
            <Container fluid className='mernMain'>
                <h2>Course on Java</h2>

                <Row>
                    <Col className='mern-left' md={4}>
                        <img src={java} />
                    </Col>

                    <Col className='mern-right' md={8}>

                        <p>
                            Java is a versatile programming language with a wide range of applications.
                            Here are some common applications of Java:
                        </p>
                        <ul>
                            <h5>1. Mobile Development</h5>
                            <li>Java is used for Android app development.</li>
                            <li>Android Studio, the official IDE for Android development, uses Java as its primary programming language.</li>

                        </ul>
                        <ul>
                            <h5>2. Desktop GUI Applications:</h5>
                            <li>Java's Swing and JavaFX libraries are used for building desktop applications with graphical user interfaces (GUIs).</li>
                            <li>Java's cross-platform compatibility makes it a popular choice for developing desktop applications that can run on different operating systems</li>

                        </ul>
                        <ul>
                            <h5>3. Web Development</h5>
                            <li>Java is widely used for server-side web development.</li>
                            <li>Java Enterprise Edition (Java EE) is commonly used for building enterprise-level web applications.</li>
                            <li>Popular frameworks and libraries for Java web development include Spring, Hibernate, Struts, and JavaServer Faces (JSF).</li>

                        </ul>
                        <ul>
                            <h5>4. Big Data Technologies</h5>
                            <li>Java is used in various big data technologies and frameworks such as Apache Hadoop, Apache Spark, and Apache Flink.</li>
                            <li>Java's scalability, performance, and concurrency features make it well-suited for handling large volumes of data</li>

                        </ul>
                        <ul>
                            <h5>5. Financial Applications</h5>
                            <li>Java is widely used in the financial industry for building trading platforms, banking applications,
                                and financial analytics software.</li>
                            <li>Java's performance, security, and reliability make it well-suited for handling financial transactions and data.</li>

                        </ul>
                        <ul>
                            <h5>6. Game Development</h5>
                            <li>Java is used for game development, both for desktop and mobile platforms.</li>
                            <li>Libraries such as LibGDX and jMonkeyEngine are popular for developing games in Java.</li>

                        </ul>

                    </Col>
                </Row>

                <Row>
                    <Col></Col>
                    <Col xs={8}>
                        <h1 className='syslabusHead'>Java Syllabus</h1>
                        <div className='mern-accordian accordion-flush'>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Java</li>
                                            <li>JDK vs JRE vs JVM</li>
                                            <li>Java basic program</li>
                                            <li>Environment Setup</li>
                                            <li>Basic Syntax</li>
                                            <li>Variables</li>
                                            <li>Data Types</li>
                                            <li>Unicode System</li>
                                            <li>Operators</li>
                                            <li>User Input</li>


                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java Control Statement</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Loop Control</li>
                                            <li>Decision Making</li>
                                            <li>If Else</li>
                                            <li>Switch Loop</li>
                                            <li>For Loops</li>
                                            <li>For-each Loops</li>
                                            <li>While Loops</li>
                                            <li>do-while Loops</li>
                                            <li>Break</li>
                                            <li>Continue</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Object Oriented Programming</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Classess and Objects</li>
                                            <li>Class Attribute</li>
                                            <li>Class Method</li>
                                            <li>Methods</li>
                                            <li>Variable Scope</li>
                                            <li>Constructors</li>
                                            <li>Access Modifier</li>
                                            <li>Inheritance</li>
                                            <li>Aggregation</li>
                                            <li>Polymorphism</li>
                                            <li>Overriding</li>
                                            <li>Overloading</li>
                                            <li>Dynamic Binding</li>
                                            <li>Static Binding</li>
                                            <li>Instance Initializer Block</li>
                                            <li>Abstraction</li>
                                            <li>Encapsulation</li>
                                            <li>Interfaces</li>
                                            <li>Packages</li>
                                            <li>Inner Classes</li>
                                            <li>Static Class</li>
                                            <li>Anonymous Class</li>
                                            <li>Singleton Class</li>
                                            <li>Wrapper Class</li>
                                            <li>Enums </li>
                                            <li>Enum Constructor</li>
                                            <li>Enums Strings</li>
                                            

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java built-in Classes</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Number </li>
                                            <li>Boolean</li>
                                            <li>Characters</li>
                                            <li>Arrays</li>
                                            <li>Date and Time</li>
                                            <li>Math Class</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java File Handling</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Create a File</li>
                                            <li>Write to Files</li>
                                            <li>Read Files</li>
                                            <li>Delete Files</li>
                                            <li>Directories</li>
                                            <li>I/O Streams</li>
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java Error and Exceptions</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Java Exceptions</li>
                                            <li>try - Catch Block</li>
                                            <li>try - with Resources</li>
                                            <li>Multi - Catch Block</li>
                                            <li>Nested try Block</li>
                                            <li>Finally Block</li>
                                            <li>throw Exception</li>
                                            <li>Exception Propagation</li>
                                            <li>Built in Exceptions</li>
                                            <li>Custom Exception</li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java Multithreading</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Multithreading</li>
                                            <li>Thread Life Cycle</li>
                                            <li>Creating a Thread</li>
                                            <li>Starting a Thread</li>
                                            <li>Joining Threads</li>
                                            <li>Naming Thread</li>
                                            <li>Thread Schedular</li>
                                            <li>Thread Pools</li>
                                            <li>Main Thread</li>
                                            <li>Thread Priority</li>
                                            <li>Daemons  Thread </li>
                                            <li>Thread Group</li>
                                            <li>Shutdown Hook</li>
                                            <li></li>

                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header> Java Synchronization </Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Synchronization</li>
                                            <li>Block Synchronization</li>
                                            <li>Static Synchronization</li>
                                            <li>Inter Thread Communication</li>
                                            <li>Thread Deadlock</li>
                                            <li>Interrupting a Thread</li>
                                            <li>Thread Control</li>
                                            <li>Reentrant Monitor</li>
                                          
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Networking</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Java Networking</li>
                                            <li>Socket Programming</li>
                                            <li>URL Processing</li>
                                            <li>URL Class</li>
                                            <li>URLConection Class </li>
                                            <li>HttpURLConection Class</li>
                                            <li>Socket Class</li>
                                            <li>Generics</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Collection</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Java Collection</li>
                                            <li>Collection Interface</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java List Interface</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>List Interface</li>
                                            <li>Array List </li>
                                            <li>Vector Class</li>
                                            <li>Stack Class</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Queue Interface</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Queue Interface</li>
                                            <li>Priority Queue</li>
                                            <li>LinkedList</li>
                                            <li>ArrayDeque</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Map Interface</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Map Interface</li>
                                            <li>HashMap</li>
                                            <li>LinkedHashMap</li>
                                            <li>WeaklHashMap</li>
                                            <li>EnupMap</li>
                                            <li>SortedMap Interface</li>
                                            <li>TreeMap</li>
                                            <li>identifyHashMap Class</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Javaset Interface</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Set Interface</li>
                                            <li>Hashset</li>
                                            <li>Enumset</li>
                                            <li>LinkedHashSet</li>
                                            <li>SortedSet Interface</li>
                                            <li>TreeSet</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Data Structure</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li>Introduction to Data Structure</li>
                                            <li>Enumaration</li>
                                            <li>Bitset Class</li>
                                            <li>Dictionary</li>
                                            <li>Hashtable</li>
                                            <li>Properties</li>
                                            
                                        </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Java Collections Algorithm</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Iterators</li>
                                        <li>Comparators</li>
                                        <li>Comparable Interface in Java</li>
                                        <li>Collection Interface</li>
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion ActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Project on Java</Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                        <li>Beginner Level Projects</li>
                                        <li>Intermediate Level Java Projects</li>
                                      
                                    </ul>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,0L30,5.3C60,11,120,21,180,53.3C240,85,300,139,360,144C420,149,480,107,540,90.7C600,75,660,85,720,122.7C780,160,840,224,900,229.3C960,235,1020,181,1080,138.7C1140,96,1200,64,1260,48C1320,32,1380,32,1410,32L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"></path>
            </svg>


        </>
    );
}

export default Java;

